/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SportDataTournamentRanking } from '../models/SportDataTournamentRanking';
import type { SportDataTournamentStandings } from '../models/SportDataTournamentStandings';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TournamentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get tournament standings
     * Get tournament standings
     * @returns SportDataTournamentStandings Success
     * @throws ApiError
     */
    public getTournamentStandingsOptaTournament({
        id,
    }: {
        /**
         * OPTA ID of the requested tournament
         */
        id: string,
    }): CancelablePromise<SportDataTournamentStandings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/tournament/{id}/standings',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get tournament rankings
     * Get tournament rankings
     * @returns SportDataTournamentRanking Success
     * @throws ApiError
     */
    public getTournamentRankingsOptaTournament({
        id,
        limit = 5,
    }: {
        /**
         * OPTA ID of the requested tournament
         */
        id: string,
        limit?: number,
    }): CancelablePromise<Array<SportDataTournamentRanking>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/tournament/{id}/rankings',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
            },
        });
    }
}
