import Cookies from 'cookies';
import JsCookies from 'js-cookie';
import newrelic from 'newrelic';
import { GetServerSidePropsContext } from 'next';
import absoluteUrl from 'next-absolute-url';

import { ExperimentGroupOrRobots, PlatformControllerService, PlatformID } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { getByKey, getWithSuffix, stringTypeCheck } from '@common/envvars';
import { logger } from '@common/logger';
import { SupertokensStatus } from '@common/supertokens/SupertokensStatus';
import { Duration } from '@common/types';
import { getPlatformIdByHostname } from '@common/utils/HostUtil';
import { getExperimentGroup } from '@web/utils/getExperimentGroup';

import { ApiProps } from './ApiProps';
import { CaptchaProps } from './CaptchaProps';
import { CachedContextData, ContextData } from './ContextData';
import { CrossPlatformByPlatformId } from './CrossPlatformByPlatformId';

const getCaptchaConfig = (platform: PlatformID): CaptchaProps => {
    const envVars = {
        NEXT_PUBLIC_CAPTCHA_SITE_KEY: false,
    };
    const values = getWithSuffix(platform, envVars);
    return {
        siteKey: values.NEXT_PUBLIC_CAPTCHA_SITE_KEY ?? '',
    };
};

const getApiConfig = (suffix: string | undefined): ApiProps => {
    const envVars = {
        NEXT_SERVER_API_HOST: false,
        NEXT_PUBLIC_API_HOST: true,
        NEXT_PUBLIC_API_TIMEOUT: false,
    };
    const values = suffix ? getWithSuffix(suffix, envVars) : process.env;
    return {
        internalHost: values.NEXT_SERVER_API_HOST ?? '',
        host: values.NEXT_PUBLIC_API_HOST ?? '',
        timeout: Number(values.NEXT_PUBLIC_API_TIMEOUT) || 10 * Duration.second,
    };
};

export const cachedContextDataByHost: Record<string, CachedContextData> = {};

export const getCachedContextData = async (
    hostname: string,
    isServerSide: boolean,
): Promise<CachedContextData> => {
    if (cachedContextDataByHost[hostname]) return structuredClone(cachedContextDataByHost[hostname]);

    const platformID = getPlatformIdByHostname(hostname);
    if (!platformID) throw Error(`Hostname ${hostname} could not be recognized`);

    const apiSuffixByHostname = isServerSide
        ? getByKey('NEXT_SERVER_API_SUFFIX_BY_HOSTNAME', stringTypeCheck)
        : undefined;
    const apiSuffix = apiSuffixByHostname ? apiSuffixByHostname.get(hostname) : undefined;
    const apiConfig = getApiConfig(apiSuffix);

    const { NEXT_PUBLIC_SOCKET_HOST } = apiSuffix
        ? getWithSuffix(apiSuffix, { NEXT_PUBLIC_SOCKET_HOST: false })
        : process.env;
    const socketConfig: ApiProps = {
        host: NEXT_PUBLIC_SOCKET_HOST ?? '',
        timeout: 0,
    };

    const sportmonksF1Config: ApiProps = {
        internalHost: process.env.NEXT_SERVER_SPORTMONKS_F1_HOST ?? '',
        host: process.env.NEXT_PUBLIC_SPORTMONKS_F1_HOST ?? '',
        timeout: Number(process.env.NEXT_PUBLIC_SPORTMONKS_F1_TIMEOUT) || 5 * Duration.second,
    };

    const crossPlatformConfig: CrossPlatformByPlatformId = process.env.NEXT_PUBLIC_CROSS_PLATFORM_CONFIG
        ? JSON.parse(process.env.NEXT_PUBLIC_CROSS_PLATFORM_CONFIG)
        : {
              br: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.bright.nl',
              },
              gp: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.gpblog.com',
              },
              vp: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.voetbalprimeur.nl',
              },
              vpbe: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.voetbalprimeur.be',
              },
              vn: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.voetbalnieuws.nl',
              },
          };

    const captchaConfig = getCaptchaConfig(platformID);

    const contextDataConfig: Pick<CachedContextData, 'config'> = {
        config: {
            api: apiConfig,
            socket: socketConfig,
            sportmonksF1: sportmonksF1Config,
            crossPlatform: crossPlatformConfig,
            captchaConfig: captchaConfig,
        },
    };

    const request = new ApiBaseRequest(contextDataConfig, false);
    const platformService = new PlatformControllerService(request);
    const [platform, contexts] = await Promise.all([
        platformService.getPlatformByPlatform({ platform: platformID }),
        platformService.getContextsPlatformByPlatform({
            platform: platformID,
        }),
    ]);

    // Make sure next.staging.voetbalprimeur.localhost works locally
    if ((process.env.APP_ENV || process.env.NODE_ENV) === 'development') {
        contexts.forEach((context) => {
            if (context.pattern) {
                context.pattern = context.pattern.replace(/\\?\.([\w\{\}\\]*?)\$/, `($1)?\.localhost$`);
            }
        });
    }

    const context =
        contexts.length === 1
            ? contexts.at(0)
            : contexts.find((context) => {
                  // do not cache if it's based on the context slug
                  if (context.slug) return false;
                  return context.pattern && new RegExp(context.pattern, 'i').test(hostname);
              });

    const contextData: CachedContextData = {
        ...contextDataConfig,
        contexts,
        context,
        hostname,
        platform,
        env: {},
    };

    /**
     * process.env.NEXT_PUBLIC_FOO variables are evaluated on build time
     * therefor we'll make them accessible through useContextData
     * See threads like https://github.com/vercel/next.js/discussions/34894
     */
    if (process.env) {
        for (const key in process.env) {
            const value = process.env[key];
            if (/^NEXT_PUBLIC_|^(APP|NODE)_ENV$/.test(key) && value) {
                contextData.env[key] = value;
            }
        }
    }

    cachedContextDataByHost[hostname] = structuredClone(contextData);
    return structuredClone(contextData);
};

const isExperimentGroup = (x: unknown): x is ExperimentGroupOrRobots =>
    typeof x === 'string' && Object.values(ExperimentGroupOrRobots).includes(x as ExperimentGroupOrRobots);

/** @deprecated Please ensure to use populateContextDataWithAds in cms/web react pages */
export const populateContextData = async (
    serverContext: GetServerSidePropsContext | false,
): Promise<ContextData> => {
    // context is passed in NextInjector either as cookies or as script element
    const cookies = serverContext ? new Cookies(serverContext.req, serverContext.res) : JsCookies;
    const cookieContext: any = serverContext
        ? {
              appAgent: cookies.get('app-agent'),
              domainID: cookies.get('domainID'),
              experimentGroup: cookies.get('experimentGroup'),
              isPlatformContext: cookies.get('isPlatformContext'),
              locale: cookies.get('locale'),
              magnoliaSessionID: cookies.get('publishing_session'),
              sAccessToken: cookies.get('sAccessToken'),
              sAntiCsrf: cookies.get('sAntiCsrf'),
              sFrontToken: cookies.get('sFrontToken'),
              sRefreshToken: cookies.get('sRefreshToken'),
              'st-last-access-token-update': cookies.get('st-last-access-token-update'),
              userAgent: cookies.get('userAgent'),
              userID: cookies.get('userID'),
              userSessionID: cookies.get('userSessionID'),
          }
        : JSON.parse((document?.getElementById('_next_context')?.textContent as string) || '{}');

    const origin = serverContext ? absoluteUrl(serverContext.req).origin : window.location.origin;
    const url = new URL(origin);
    const hostname = url.hostname;
    const cachedContextData = await getCachedContextData(hostname, !!serverContext);

    logger.registerAdditionalContext({
        origin,
        hostname,
        pathname: serverContext ? serverContext.req.url : undefined,
        route: serverContext ? serverContext.resolvedUrl : undefined,
    });

    const context =
        cachedContextData.context ||
        cachedContextData.contexts.find((context) => {
            if (cookieContext.domainID) return context.id === Number(cookieContext.domainID);

            const isMatching = context.pattern && new RegExp(context.pattern, 'i').test(hostname);
            // Compare locale with slug ( since original path is not available in the serverContext due to the rewrites )
            if (isMatching && context.slug) {
                const locale = serverContext ? serverContext.locale : cookieContext.locale;
                return new RegExp(context.slug).test(locale);
            }

            return isMatching;
        });

    if (!context) {
        throw Error(`Context could not be determined for ${hostname}`);
    }

    // TODO: remove this once php code is gone
    let isDarkWebCall: boolean = false;
    if (
        serverContext &&
        serverContext.req.headers['user-agent'] &&
        /Guzzle/.test(serverContext.req.headers['user-agent'])
    ) {
        isDarkWebCall = true;
    }

    const contextData: ContextData = {
        ...cachedContextData,
        context,
        path: serverContext ? serverContext.req.url : undefined,
        adsEnabled: true,
        allowBetting: true,
        allowTracking: true,
        isPlatformContext: cookieContext?.isPlatformContext || false,
        isDarkWebCall,
        magnoliaSessionID: cookieContext.magnoliaSessionID || '',
        userID: Number(cookieContext.userID) || 0,
        userSessionID: (cookieContext.userSessionID as string) || '',
        userAgent:
            (cookieContext.userAgent && decodeURIComponent(cookieContext.userAgent)) ||
            (serverContext && serverContext.req.headers['user-agent']) ||
            'unknown',
        supertokens: {
            status: SupertokensStatus.UNKNOWN,
            sAccessToken: cookieContext.sAccessToken ?? '',
            sRefreshToken: cookieContext.sRefreshToken ?? '',
            sFrontToken: cookieContext.sFrontToken ?? '',
            sAntiCsrf: cookieContext.sAntiCsrf ?? '',
            'st-last-access-token-update': cookieContext['st-last-access-token-update'] ?? '',
        },
    };

    if (serverContext && !cookieContext?.experimentGroup && !isDarkWebCall) {
        contextData.experimentGroup = getExperimentGroup(serverContext.req!);
        cookies.set('experimentGroup', contextData.experimentGroup, {
            path: '/',
            expires: new Date(Date.now() + 365 * Duration.day),
        });
    } else if (cookieContext?.experimentGroup) {
        contextData.experimentGroup = cookieContext?.experimentGroup;
    }

    if (cookieContext.appAgent) contextData.userAgent += ' ' + cookieContext.appAgent;

    if (/Playwright/i.test(contextData.userAgent)) {
        contextData.adsEnabled = false;
        contextData.allowTracking = false;
    }

    if (serverContext) {
        if (serverContext.query) {
            for (const [key, value] of Object.entries(serverContext.query)) {
                if (typeof value !== 'undefined') {
                    newrelic.addCustomAttribute(key, value.toString());
                    if (key === 'experimentGroup' && isExperimentGroup(value)) {
                        contextData.experimentGroup = value;
                    }
                }
            }
            if (serverContext.query.ads === 'false') {
                contextData.adsEnabled = false;
            }
            if (serverContext.query.tracking === 'false') {
                contextData.allowTracking = false;
            }
        }

        newrelic.addCustomAttribute('userAgent', contextData.userAgent);
        newrelic.addCustomAttribute('platform', contextData.context.platform);
        newrelic.addCustomAttribute('context.id', contextData.context.id);
        newrelic.addCustomAttribute('hostname', contextData.hostname);
        newrelic.addCustomAttribute('path', serverContext.resolvedUrl);
    }

    return contextData;
};
